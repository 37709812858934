import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"

export function ParadiseUserAppInstallations({
  appInstallations,
}: {
  appInstallations: TParadiseUser["app_installations"]
}) {
  const header = [
    <div key={"vendor"}>Vendor</div>,
    <div key={"version"}>Version</div>,
  ]
  const rows = appInstallations.map((app) => (
    <>
      <div>{app.vendor === "gcm" ? "Android" : "iOS"}</div>
      <div>{app.app_version}</div>
    </>
  ))

  return (
    <div>
      <ParadiseDetailsSection title="App installations" />
      <ParadiseTable
        header={header}
        rows={rows}
        templateColumns="min-content auto"
      />
    </div>
  )
}
