import { useState } from "react"

import { ButtonGroup, Menu, MenuItem } from "@material-ui/core"

import { ParadiseDeleteUserConfirmationDialog } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseDeleteUserConfirmationDialog"
import { ParadiseResetPasswordConfirmationDialog } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseResetPasswordConfirmationDialog"
import { ParadiseImpersonateQrPopup } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserImpersonationCode"
import { ActionsWrapper } from "src/components/Paradise/sharedStyles"
import { useImpersonate } from "src/components/Paradise/useImpersonate"
import { RequireRoles } from "src/components/RestrictedRoles/RequireRoles"
import { chargebeeUrl, intercomUrl, kibanaUrl } from "src/constants/hrefs"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { UserRoles } from "src/data/user/user"
import { MButton } from "src/ui/Button/MButton"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import ChevronDownIcon from "src/ui/icons/chevron-down.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"

const externalLinks = (
  user: TParadiseUser,
  organizations?: IOrganization[]
) => {
  const orgIds = organizations?.map((org) => org.id)
  return {
    intercom: intercomUrl(user.email),
    kibana: kibanaUrl(user.id, orgIds),
    chargebee: chargebeeUrl(user.id),
  }
}

export function ParadiseUserActions({ user }: { user: TParadiseUser }) {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false)
  const [showImpersonateQrPopup, setShowImpersonateQrPopup] = useState(false)
  const impersonation = useImpersonate()

  const fetchedOrgs = useFetchOrganizations({
    params: {
      membership_user_id: user.id,
    },
  })

  function showQrDialog() {
    setShowImpersonateQrPopup(true)
    impersonation.impersonateQR(user.id)
  }

  return (
    <>
      <ActionsWrapper>
        <ExternalLink href={externalLinks(user).intercom}>
          Intercom
        </ExternalLink>
        <ExternalLink
          href={externalLinks(user, fetchedOrgs.data?.organizations).kibana}
        >
          Kibana
        </ExternalLink>
        <ExternalLink href={externalLinks(user).chargebee}>
          Chargebee
        </ExternalLink>
        <RequireRoles roles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}>
          <SplitButton
            label="Impersonate"
            onClick={() => impersonation.impersonate(user.id, "web-app")}
            disabled={user.deleted}
          >
            <MenuItem onClick={() => showQrDialog()}>
              Impersonate on phone
            </MenuItem>
            <MenuItem
              onClick={() => impersonation.impersonate(user.id, "store")}
            >
              Impersonate in store
            </MenuItem>
          </SplitButton>
        </RequireRoles>
        <RequireRoles roles={[UserRoles.SUPERADMIN]}>
          <MoreButton
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            menuItems={[
              {
                key: "reset-password",
                contents: <MenuItem>Reset password</MenuItem>,
                onClick: () => {
                  setShowResetPasswordDialog(true)
                },
              },
              {
                key: "delete-user",
                contents: <MenuItem>Delete user</MenuItem>,
                onClick: () => setShowDeleteConfirmDialog(true),
              },
            ]}
          />
        </RequireRoles>
      </ActionsWrapper>
      <ParadiseDeleteUserConfirmationDialog
        open={showDeleteConfirmDialog}
        email={user.email}
        userId={user.id}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />
      <ParadiseImpersonateQrPopup
        imgSource={impersonation.data?.qr}
        open={showImpersonateQrPopup}
        onClose={() => setShowImpersonateQrPopup(false)}
        isLoading={impersonation.isLoading}
      />
      <ParadiseResetPasswordConfirmationDialog
        open={showResetPasswordDialog}
        userId={user.id}
        onClose={() => setShowResetPasswordDialog(false)}
      />
    </>
  )
}

function SplitButton({
  label,
  onClick,
  disabled,
  children,
}: {
  label: string
  onClick: () => void
  disabled?: boolean
  children: React.ReactNode
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  function handleDropdownClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget.parentElement)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <ButtonGroup size="small" disabled={disabled}>
      <MButton variant="subtle" onClick={onClick} color="default">
        {label}
      </MButton>
      <MButton
        variant="subtle"
        color="default"
        onClick={handleDropdownClick}
        style={{ padding: "4px" }}
      >
        <ChevronDownIcon width={16} height={16} />
      </MButton>
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null} // See https://github.com/mui/material-ui/issues/7961, fixes vertical alignment
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {children}
      </Menu>
    </ButtonGroup>
  )
}
