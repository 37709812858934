import { useState } from "react"
import styled from "styled-components"

import { addYears, format } from "date-fns"

import { usePatchUserHomeToken } from "src/data/homes/queries/homeTokenQueries"
import { THomeToken } from "src/data/homes/types/homeTokenTypes"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"
import { dateToUTCISOStringWithoutTzConversion } from "src/utils/l10n"

export function ParadiseUserUpdateHomeTokenDialog({
  open,
  onClose,
  token,
}: {
  open: boolean
  token: THomeToken
  onClose: () => void
}) {
  const patchUserHomeToken = usePatchUserHomeToken()
  const [expiresAt, setExpiresAt] = useState(
    dateToUTCISOStringWithoutTzConversion(new Date(token.expires_at))
  )

  const currentDateUTC = dateToUTCISOStringWithoutTzConversion(new Date())
  const maxDate = addYears(new Date(currentDateUTC), 1).toISOString()
  const minDate =
    new Date(currentDateUTC) > new Date(token.expires_at)
      ? currentDateUTC
      : token.expires_at

  function handleUpdate() {
    patchUserHomeToken.mutate(
      {
        userId: token.owner.id,
        tokenId: token.id as string,
        body: {
          expires_at: expiresAt,
        },
      },
      {
        onSuccess: onClose,
      }
    )
  }

  return (
    <MDialog
      title="Edit expiration date"
      description={`The expiration date of the home token cannot be set to an earlier date than ${formatDate(minDate)} and cannot be set to a date after ${formatDate(maxDate)}.`}
      open={open}
      onClose={onClose}
      onConfirm={() => {
        handleUpdate()
      }}
      confirmLabel="Save"
      loading={patchUserHomeToken.isLoading}
      error={
        patchUserHomeToken.error?.response?.data.message ||
        patchUserHomeToken.error?.message
      }
    >
      <StyledInput
        type="date"
        min={formatDate(minDate)}
        max={formatDate(maxDate)}
        value={formatDate(expiresAt)}
        onChange={(e) => {
          setExpiresAt(e.target.value)
        }}
      />
      <MBanner
        type="warning"
        title="This action is irreversible. Once changed, the token's expiration date cannot be set to an earlier date."
        style={{ marginTop: 16 }}
      />
    </MDialog>
  )
}

function formatDate(date: string) {
  if (date === "") {
    return ""
  }
  return format(new Date(date), "yyyy-MM-dd")
}

const StyledInput = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 14px;
  color: #333;
`
