import styled from "styled-components"

import { usePutParadisePasswordReset } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const RESET_PASSWORD_EXPIRATION_HOURS = 48

export function ParadiseResetPasswordConfirmationDialog({
  open,
  userId,
  onClose,
}: {
  open: boolean
  userId: string
  onClose: () => void
}) {
  const useResetPassword = usePutParadisePasswordReset()

  function resetPassword() {
    useResetPassword.mutate(
      {
        path: { user_id: userId },
        query: { expiration_minutes: RESET_PASSWORD_EXPIRATION_HOURS * 60 },
      },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )
  }

  return (
    <MDialog
      title="Reset password?"
      open={open}
      onClose={onClose}
      confirmLabel="Reset password"
      onConfirm={resetPassword}
      loading={useResetPassword.isLoading}
    >
      <Wrapper>
        <MText variant="body">
          This will trigger the reset password flow for the user with a link to
          reset their password that is valid for{" "}
          {RESET_PASSWORD_EXPIRATION_HOURS} hours (instead of the 15 minutes
          default).
        </MText>
        {useResetPassword.error && (
          <MBanner type="warning">
            {useResetPassword.error.message}:{" "}
            {useResetPassword.error.response?.data.message}
          </MBanner>
        )}
      </Wrapper>
    </MDialog>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
