import { useState } from "react"
import styled from "styled-components"

import { IconButton } from "@minuthq/meatball-ui-react"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import {
  useDeleteParadiseUserNote,
  usePostParadiseUserNote,
} from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { mColors } from "src/ui/colors"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import TrashIcon from "src/ui/icons/trash.svg"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function ParadiseUserNotes({ user }: { user: TParadiseUser }) {
  const [createNoteOpen, setCreateNoteOpen] = useState(false)
  const [noteIdToDelete, setNoteIdToDelete] = useState<string | null>(null)

  const postUserNote = usePostParadiseUserNote()
  const deleteUserNote = useDeleteParadiseUserNote()

  function deleteNote(note_id: string) {
    setNoteIdToDelete(null)
    deleteUserNote.mutateAsync({ path: { user_id: user.id, note_id } })
  }

  function createNote(content: string) {
    setCreateNoteOpen(false)
    postUserNote.mutateAsync({ path: { user_id: user.id }, body: { content } })
  }

  const rows = user.notes?.map((note) => {
    return (
      <>
        <NoteRow>
          <div>
            <NoteContent>{note.content}</NoteContent>
            <MText variant="nano" color="secondary">
              {note.author_email} (
              {formatDate({ date: note.created_at, clockType: "24" })})
            </MText>
          </div>
          <div>
            <IconButton
              Icon={TrashIcon}
              variant="minimal"
              color="destructive"
              onClick={() => {
                setNoteIdToDelete(note.note_id)
              }}
            />
          </div>
        </NoteRow>
      </>
    )
  })

  return (
    <>
      <Wrapper>
        <ParadiseDetailsSection
          title="Notes"
          buttonLabel="Add note"
          onButtonClick={() => {
            setCreateNoteOpen(true)
          }}
        />
        <TableWrapper>{rows}</TableWrapper>
      </Wrapper>
      <CreateNoteDialog
        onClose={() => {
          setCreateNoteOpen(false)
        }}
        onSave={(content) => createNote(content)}
        open={createNoteOpen}
      />
      {noteIdToDelete && (
        <DeleteNoteDialog
          noteId={noteIdToDelete}
          onClose={() => setNoteIdToDelete(null)}
          onDelete={deleteNote}
          open={noteIdToDelete !== null}
        />
      )}
    </>
  )
}

function CreateNoteDialog({
  onClose,
  onSave,
  open,
}: {
  onClose: () => void
  onSave: (content: string) => void
  open: boolean
}) {
  const [noteContent, setNoteContent] = useState("")
  return (
    <>
      <ConfirmDialog
        open={open}
        onClose={onClose}
        title="Create note"
        onConfirm={() => {
          onSave(noteContent)
          setNoteContent("")
        }}
      >
        <MTextField
          value={noteContent}
          onChange={(value) => setNoteContent(value)}
          placeholder="Note content"
        />
      </ConfirmDialog>
    </>
  )
}

function DeleteNoteDialog({
  noteId,
  onClose,
  onDelete,
  open,
}: {
  noteId: string
  onClose: () => void
  onDelete: (noteId: string) => void
  open: boolean
}) {
  return (
    <ConfirmDialog
      title={"Are you sure you want to delete this note?"}
      onClose={onClose}
      confirmButtonProps={{ color: "destructive" }}
      onConfirm={() => onDelete(noteId)}
      loading={false}
      open={open}
    >
      <div>
        <p>{`This action is irreversible.`}</p>
      </div>
    </ConfirmDialog>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
`

const TableWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

const NoteRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: ${spacing.M};
  border-bottom: 1px solid ${mColors.divider};
  padding: ${spacing.M} 0;
`

const NoteContent = styled(MText)`
  white-space: wrap;
  width: auto;
`
