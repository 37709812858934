import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { IdWrapper } from "src/components/Paradise/sharedStyles"
import {
  useDeleteUserDecision,
  useFetchUserDecisions,
} from "src/data/userDecisions/userDecisionsQueries"
import { TUserDecision } from "src/data/userDecisions/userDecisionsTypes"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import InfoIcon from "src/ui/icons/info.svg"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseUserDecisions({ userId }: { userId: string }) {
  const { limit, offset, setOffset } = useUrlPager({ initialLimit: 50 })

  const fetchUserDecisions = useFetchUserDecisions({
    userId,
    filters: {
      limit,
      offset,
      include_deleted: true,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const deleteUserDecision = useDeleteUserDecision({
    userId,
  })

  const tableColumns: TableColumn<TUserDecision>[] = [
    {
      value: "id",
      label: "Id",
      renderCell: (data) => (
        <div>
          <IdWrapper>
            <MText variant="bodyS">{data.decision_id}</MText>
            {data.deleted && (
              <MTooltip title="Deleted">
                <InfoIcon width={12} />
              </MTooltip>
            )}
          </IdWrapper>
        </div>
      ),
      columnWidth: "1fr",
    },
    {
      value: "decision",
      label: "Decision",
      renderCell: (data) => <MText variant="bodyS">{data.decision}</MText>,
      columnWidth: "1fr",
    },
    {
      value: "created_at",
      label: "Created at",
      renderCell: (data) => (
        <MText variant="bodyS">
          {formatUtcDate({
            date: data.created_at,
          })}
        </MText>
      ),
      columnWidth: "1fr",
    },
    {
      value: "actions",
      label: "",
      renderCell: (data) => (
        <div>
          <MoreButton
            onDelete={() => {
              deleteUserDecision.mutate(data.decision_id)
            }}
            disabled={data.deleted}
          />
        </div>
      ),
      columnWidth: 40,
    },
  ]

  const { headerElements, rows, templateColumns } = useTableColumns({
    columns: tableColumns,
    data: fetchUserDecisions.data?.decisions,
  })

  return (
    <div>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        isLoading={fetchUserDecisions.isLoading}
        isLoadingMore={fetchUserDecisions.isPreviousData}
        error={{
          title: "Failed to fetch user decisions",
          description: fetchUserDecisions.error?.message,
          hasError: fetchUserDecisions.isError,
        }}
      />
      <Pager
        limit={limit}
        offset={offset}
        totalCount={fetchUserDecisions.data?.paging?.total_count}
        setOffset={setOffset}
      />
    </div>
  )
}
