import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDevicesTable } from "src/components/Paradise/ParadiseDevicesTable"
import { Overview } from "src/components/Paradise/sharedStyles"
import { useParadiseDevicesFilter } from "src/components/Paradise/useParadiseDevicesFilter"
import { useFetchParadiseDevices } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { MText } from "src/ui/MText"

const LIMIT = 15

export function ParadiseUserDevices({ userId }: { userId: string }) {
  const { filter, sortedFilter, setFilter, offset, setOffset } =
    useParadiseDevicesFilter()

  const fetchedDevices = useFetchParadiseDevices({
    filter: {
      user_id: userId,
      limit: LIMIT,
      offset,
      ids: filter.devices_id || undefined,
      description: filter.devices_description || undefined,
      include_inactive: filter.devices_include_inactive || undefined,
      offline: filter.devices_offline ?? undefined,
      hardware_version: filter.devices_hardwareVersion ?? undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })
  const devices = fetchedDevices.data?.devices ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Devices" />

      <Overview>
        <MText variant="nano">Total devices</MText>
        <MText variant="subtitleS">
          {fetchedDevices.data?.paging?.total_count}
        </MText>
      </Overview>

      <ParadiseDevicesTable
        devices={devices}
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        filter={filter}
        setFilter={setFilter}
        sortedFilters={sortedFilter}
        totalCount={fetchedDevices.data?.paging?.total_count}
        isLoading={fetchedDevices.isPreviousData}
        error={{
          hasError: fetchedDevices.isError,
          title: fetchedDevices.error?.message,
        }}
        disableColumnHiding
        hiddenColumns={["owner"]}
      />
    </div>
  )
}
