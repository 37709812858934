import { useMemo } from "react"

import { MultiPillSelectResult } from "src/components/Paradise/MultiPillSelect"
import { ParadiseSettingMultiPillSelect } from "src/components/Paradise/ParadiseSettings/ParadiseSettingMultiPillSelect"
import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import {
  useFetchAvailableUserRoles,
  usePutParadiseUpdateUserRoles,
} from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { isSuperAdmin } from "src/data/user/logic/accessLogic"
import { MBadge } from "src/ui/MBadge/MBadge"
import { unique } from "src/utils/genericUtil"

export function ParadiseUserRoles({
  userId,
  currentRoles,
}: {
  userId: string
  currentRoles: string[]
}) {
  const loggedInUser = useGetUser()
  const putParadiseUpdateUserRoles = usePutParadiseUpdateUserRoles()

  const fetchAvailableUserRoles = useFetchAvailableUserRoles()

  async function handleSave(
    selectedRoles: MultiPillSelectResult[]
  ): TSettingContainerOnSaveReturnType {
    try {
      await putParadiseUpdateUserRoles.mutateAsync({
        userId,
        roles: selectedRoles.map((r) => r.id),
      })
      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: true,
      }
    }
  }

  const displayValue = useMemo(() => {
    if (currentRoles.length > 0) {
      return (
        <PillsWrapper>
          {currentRoles.map((tag) => (
            <MBadge key={tag} size="small">
              {tag}
            </MBadge>
          ))}
        </PillsWrapper>
      )
    }

    return "-"
  }, [currentRoles])

  const availableRoles = fetchAvailableUserRoles.data ?? []

  return (
    <ParadiseSettingMultiPillSelect
      title="Roles"
      displayValue={displayValue}
      initialSelected={convertToDropDownResult(currentRoles)}
      options={convertToDropDownResult(
        unique([...currentRoles, ...availableRoles])
      )}
      onSave={handleSave}
      disabled={!isSuperAdmin(loggedInUser.roles)}
    />
  )
}

function convertToDropDownResult(arr: string[]): MultiPillSelectResult[] {
  return arr.map((t) => ({ id: t, name: t }))
}
