import { IParadiseUserEvent } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseUserEventRow({ event }: { event: IParadiseUserEvent }) {
  return (
    <>
      <div>{formatUtcDate({ date: event.created_at })}</div>
      <div>{event.type}</div>
      {event.device_id ? (
        <InternalLink to={Routes.ParadiseDevice.location(event.device_id)}>
          {event.device_id}
        </InternalLink>
      ) : (
        <div></div>
      )}
      {event.home_id ? (
        <InternalLink to={Routes.ParadiseHome.location(event.home_id)}>
          {event.home_id}
        </InternalLink>
      ) : (
        <div></div>
      )}
    </>
  )
}
