import { useState } from "react"
import styled from "styled-components"

import { CircularProgress, spacing } from "@minuthq/meatball-ui-react"
import { addDays } from "date-fns"

import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  useFetchParadiseUserEmail,
  useFetchParadiseUserEmails,
} from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import {
  IParadiseUserEmail,
  IParadiseUserEmailResponse,
} from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { TextButton } from "src/ui/Button/TextButton"
import { mColors } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import IIcon from "src/ui/icons/i-icon.svg"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { formatDate } from "src/utils/l10n"

const POSTMARK_RETENTION_DAYS = 45

export function ParadiseUserEmails({ userId }: { userId: string }) {
  const { limit, offset, setOffset } = useUrlPager({ initialLimit: 10 })
  const [selectedEmailId, setSelectedEmailId] = useState<string | null>(null)

  const fetchParadiseUserEmails = useFetchParadiseUserEmails({
    userId,
    filter: {
      limit,
      offset,
    },
  })

  const { data: emails, isLoading } = fetchParadiseUserEmails

  const headers: TableColumn<IParadiseUserEmail>[] = [
    {
      value: "sent_at",
      label: "Sent at",
      columnWidth: "auto",
      renderCell: (email) => (
        <MText variant="bodyS">{formatDate({ date: email.sent_at })}</MText>
      ),
    },
    {
      value: "status",
      label: "Status",
      columnWidth: "auto",
      renderCell: (email) => (
        <MText variant="bodyS">
          <EmailBadge userId={userId} emailId={email.email_id} />
        </MText>
      ),
    },
    {
      value: "subject",
      label: "Subject",
      columnWidth: "1fr",
      renderCell: (email) => <MText variant="bodyS">{email.subject}</MText>,
    },
    {
      value: "actions",
      label: "",
      columnWidth: "auto",
      renderCell: (email) => (
        <OpenEmailButton onClick={() => setSelectedEmailId(email.email_id)}>
          <ContentButton>
            <IIcon color="black" width={12} />
          </ContentButton>
        </OpenEmailButton>
      ),
    },
  ]

  const { headerElements, rows, templateColumns } = useTableColumns({
    columns: headers,
    data: emails?.emails ?? [],
  })

  return (
    <div>
      <EmailDialog
        userId={userId}
        open={!!selectedEmailId}
        onClose={() => setSelectedEmailId(null)}
        emailId={selectedEmailId}
      />
      <ParadiseTable
        header={headerElements}
        templateColumns={templateColumns}
        isLoading={isLoading}
        rows={rows ?? []}
      />

      <Pager
        limit={limit}
        offset={offset}
        totalCount={fetchParadiseUserEmails.data?.paging.total_count}
        setOffset={setOffset}
      />

      <MBanner type="info" size="small">
        Emails are stored for {POSTMARK_RETENTION_DAYS} days
      </MBanner>
    </div>
  )
}

function EmailBadge({ userId, emailId }: { userId: string; emailId: string }) {
  // This query is not enabled until the user clicks the status badge
  const [isEnabled, setIsEnabled] = useState(false)
  const fetchSelectedEmail = useFetchParadiseUserEmail({
    userId,
    emailId: emailId,
    options: {
      enabled: isEnabled,
      onSuccess: () => setIsEnabled(false),
    },
  })

  const emailDetails = fetchSelectedEmail.data

  if (fetchSelectedEmail.isFetching) {
    return (
      <MBadge color="info">
        <CircularProgress size={10} />
      </MBadge>
    )
  }

  if (emailDetails?.opened === true) {
    return <MBadge color="good">Opened</MBadge>
  }

  if (emailDetails?.opened === false) {
    return <MBadge color="warning">Not opened</MBadge>
  }

  return (
    <div onClick={() => setIsEnabled(true)} style={{ cursor: "pointer" }}>
      <MBadge color="info">Click to load</MBadge>
    </div>
  )
}

function EmailDialog({
  userId,
  open,
  onClose,
  emailId,
}: {
  userId: string
  open: boolean
  onClose: () => void
  emailId: string | null
}) {
  const fetchSelectedEmail = useFetchParadiseUserEmail({
    userId,
    emailId: emailId ?? "",
    options: {
      enabled: !!emailId,
      staleTime: 5 * 60 * 1000,
    },
  })

  const email = fetchSelectedEmail.data

  const isLoading = fetchSelectedEmail.isLoading
  return (
    <MDialog
      width="large"
      open={open}
      onClose={onClose}
      onConfirm={onClose}
      hideClose
      confirmLabel="Close"
      title={<DialogHeader email={email} isLoading={false} />}
    >
      {!isLoading && (
        <>
          <div>
            <iframe
              sandbox=""
              srcDoc={email?.body ?? ""}
              style={{
                width: "100%",
                height: "100%",
                minHeight: "500px",
                border: "1px solid #e0e0e0",
                borderRadius: "10px",
                marginBottom: spacing.M,
              }}
            ></iframe>
          </div>
          {email && (
            <MBanner type="info" fullWidth>
              This email will be stored until{" "}
              {formatDate({
                date: addDays(
                  new Date(email.sent_at),
                  POSTMARK_RETENTION_DAYS
                ).toISOString(),
              })}
            </MBanner>
          )}
        </>
      )}
    </MDialog>
  )
}

function DialogHeader({
  email,
  isLoading,
}: {
  email: IParadiseUserEmailResponse | undefined
  isLoading: boolean
}) {
  const labels = ["Subject", "Sent at", "From", "Status"]

  if (isLoading) {
    return (
      <DialogHeaderWrapper>
        {labels.map((label) => (
          <>
            <DialogHeaderLabel key={label} variant="bodyS">
              {label}:
            </DialogHeaderLabel>
            <MSkeleton variant="text" width="100%" height={20} />
          </>
        ))}
      </DialogHeaderWrapper>
    )
  }

  return (
    <DialogHeaderWrapper>
      <DialogHeaderLabel variant="bodyS">Subject:</DialogHeaderLabel>
      <MText variant="subtitle">{email?.subject}</MText>
      <DialogHeaderLabel variant="bodyS">Sent at:</DialogHeaderLabel>
      <MText variant="subtitleS">
        {formatDate({ date: email?.sent_at ?? "" })}
      </MText>
      <DialogHeaderLabel variant="bodyS">From:</DialogHeaderLabel>
      <MText variant="subtitleS">{email?.from}</MText>
      <DialogHeaderLabel variant="bodyS">Status:</DialogHeaderLabel>
      {email?.opened ? (
        <BadgeContainer>
          <MBadge color="good" size="small">
            Opened ({formatDate({ date: email?.opened_at ?? "" })})
          </MBadge>
        </BadgeContainer>
      ) : (
        <BadgeContainer>
          <MBadge color="warning" size="small">
            Not opened
          </MBadge>
        </BadgeContainer>
      )}
    </DialogHeaderWrapper>
  )
}

const OpenEmailButton = styled.div`
  cursor: pointer;
`

const DialogHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS};
  align-items: center;
  padding-left: ${spacing.M};
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
`

const DialogHeaderLabel = styled(MText)`
  justify-self: flex-end;
`

const ContentButton = styled(TextButton)`
  background-color: ${mColors.divider};
  padding: ${spacing.XS};
  border-radius: 5px;
  transition: all 0.2s;

  &:hover {
    background-color: ${mColors.dividerDark};
  }
`
