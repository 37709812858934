import { useParadiseOrdersFilter } from "src/components/Paradise/ParadiseOrders/useParadiseOrdersFilter"
import { ParadiseOrdersTable } from "src/components/Paradise/ParadiseOrdersTable"
import { useFetchParadiseOrders } from "src/data/paradise/paradiseOrders/paradiseOrderQueries"

const LIMIT = 50

export function ParadiseUserOrders({ userId }: { userId: string }) {
  const { filter, setFilter, offset, setOffset, sortedFilters } =
    useParadiseOrdersFilter()

  const fetchParadiseOrders = useFetchParadiseOrders({
    filter: {
      limit: LIMIT,
      offset,
      user_ids: userId,
      order_number: filter.orders_order_number || undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })

  return (
    <ParadiseOrdersTable
      orders={fetchParadiseOrders.data?.orders || []}
      filter={filter}
      setFilter={setFilter}
      sortedFilters={sortedFilters}
      totalCount={fetchParadiseOrders.data?.paging.total_count}
      offset={offset}
      setOffset={setOffset}
      limit={LIMIT}
      isLoading={fetchParadiseOrders.isLoading}
      error={{
        hasError: fetchParadiseOrders.isError,
        title: fetchParadiseOrders.error?.message,
      }}
      hiddenColumns={["user_id"]}
      disableColumnHiding
    />
  )
}
